.item {
  width: fit-content;
  padding: 8px 24px;
  background-color: var(--brand-atom-white-bg);
  border: 1px solid var(--brand-text-dark-gray-opacity-40);
  border-radius: 8px;
  cursor: pointer;
}

.itemSelected {
  background-color: var(--bg-color-button-secondary);
  border-color: var(--brand-text-primary);
}
