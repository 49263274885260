.checkbox {
  align-items: center;
  position: relative;
  color: var(--onboarding-slategrey-900);
  outline: none;
  font-family: var(--caption-medium-11-fontFamily-new);
}

.checkbox input[type="checkbox"] {
  opacity: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  z-index: 99;
}

.checkbox.disabled {
  opacity: 0.7;
}

.checkbox.disabled input[type="checkbox"] {
  cursor: default;
}

.iconContainer {
  flex: none;
  width: 20px;
  height: 20px;
}

.iconContainer > svg {
  width: 20px;
  height: 20px;
}

.checkbox.large.focus {
  box-shadow: var(--focus-ring-box-shadow);
}

[dir="rtl"] .checkbox {
  direction: rtl;
}