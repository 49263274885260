.dataTable.root {
  border: none;
}

.dataTable.root .columnSeparator {
  display: none;
}

.dataTable.root .columnHeaderTitleContainer {
  padding: 0;
}

.dataTable .columnHeaders,
.dataTable .main {
  padding: 0 1.5rem;
}

.dataTable .columnHeaderTitle {
  font-size: 14px;
  font-family: var(--brand-font-family);
  font-weight: 700;
  color: var(--brand-text-gray-light);
}

.dataTable .row.selected {
  background-color: transparent;
}

.dataTable .cell {
  font-weight: 700;
}

.dataTable .checkboxInput:hover,
.dataTable .checkboxInput:focus {
  background-color: transparent;
}

.dataTable .checkboxInput svg {
  width: 0.8em;
  height: 0.8em;
  color: #D2D2D2;
  border-radius: 1px;
}

.dataTable .checkboxInput input:checked+svg {
  color: var(--brand-text-primary);
}

.dataTable .footerContainer p,
.dataTable .selectedRowCount {
  color: var(--brand-text-gray-light);
}
