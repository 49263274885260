.overview-stat-card {
  &.MuiPaper-root.MuiCard-root {
    width: 205px;
    border-radius: 0.5rem;
    border-color: var(--brand-divider-white);
  }
}

.new-requests-table, .overdue-tests-table {
  &.MuiTableContainer-root {
    border: 1px solid var(--brand-divider-white);
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    background-color: var(--brand-white);
  }

  .MuiTableCell-root {
    padding: 0.75rem 1.5rem;
    border-color: var(--brand-divider-white);

    &.MuiTableCell-head {
      color: var(--brand-text-gray-light);
      font-family: var(--brand-font-family);
      font-weight: bold;
    }

    &.MuiTableCell-body {
      padding: 0.625rem 1.5rem;
    }
  }
}
