.topCenterAlert {
  -webkit-transform: translateX(-50%);
  -moz-transform:  translateX(-50%);
  transform:translateX(-50%);
  top: 10px;
}

.bottomCenterAlert {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 10px;
}

.alertContainer {
  min-width: 342px;
  padding: 15px;
  padding-left: 11px;
  padding-right: 19px;
  border: 1px solid;
  border-left: 6px solid;
  border-radius: 6px;
  position: absolute;
  left: 50%;
  z-index: 10000;
}

.alertContainer svg {
  cursor: pointer;
}

.successAlert {
  border: 1px solid var(--brand-success);
  border-left: 6px solid var(--brand-success);
  background-color: var(--brand-success-bg);
}

.dangerAlert {
  border: 1px solid var(--brand-danger);
  border-left: 6px solid var(--brand-danger);
  background-color: var(--brand-danger-bg);
}

.warningAlert {
  border: 1px solid var(--brand-warning);
  border-left: 6px solid var(--brand-warning);
  background-color: var(--brand-warning-bg);
}

.secondaryAlert {
  border: 1px solid var(--brand-text-dark-gray);
  border-left: 6px solid var(--brand-text-dark-gray);
  background-color: var(--brand-text-gray-lighter);
}

.primaryAlert {
  border: 1px solid var(--brand-dark-blue);
  border-left: 6px solid var(--brand-dark-blue);
  background: var(--brand-primary-border);
  color: var(--brand-white);
}
