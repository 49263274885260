.order-info-container {
  .section-title {
    background-color: var(--brand-template-bg);
    padding: 0.75rem 1.5rem;
  }

  .order-tracking {
    position: relative;
    padding: 1.5rem 0;
    width: 100%;
    .tracker-circle,
    .tracker-bar {
      position: absolute;
      background-color: #EDEDED;
      left: 45%;
      &.done {
        background-color: #DDE1F9;
      }
    }
    .tracker-circle {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      top: 1.2rem;
      z-index: 1;
      &.active {
        background: radial-gradient(circle, var(--brand-card-bg) 20%, #5E72EB 40%);
      }
    }
    .tracker-bar:not(.last) {
      width: 100%;
      height: 4px;
    }
  }
}

