.user-info {
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#profile-pic {
  cursor: pointer;
}

#profile-menu {
  .MuiPaper-root {
    margin-top: 0.25rem;
    margin-left: -2.5rem;
    width: 12rem;
  }
}
