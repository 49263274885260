.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

/* ? shared style */
.container .inputBox {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  flex-wrap: nowrap;
  transition: 200ms;
}

.container input {
  height: 24px;
}

.container .inputBox svg {
  width: 24px;
  height: 24px;
}

.container .inputBox .inputTag {
  cursor: text;
  flex-grow: 1;
  outline: none;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #e2e6e9;
  color: var(--color-natural-black-default);
  caret-color: var(--color-natural-black-default);
  width: 100%;
}

.container .inputBox.inputSuffix .inputTag {
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
}

.container:not([disabled]) .subContainer:focus-within .inputTag {
  border-color: var(--color-brand-primary-default-new);
}

.container .inputBox.inputPrefix .inputTag {
  border-start-start-radius: 0px;
  border-end-start-radius: 0px;
}

.container .inputBox .inputTag:focus:not(:disabled),
.container .inputBox .inputTag:active:not(:disabled),
.container .inputBox .inputTag:hover:not(:disabled) {
  border-color: var(--color-brand-primary-default-new);
}

.container .subContainer {
  display: flex;
  align-items: center;
  flex: 1;
}

.container .subContainer:hover .inputTag:not(:disabled),
.container .subContainer:hover .inputTag:not(:disabled),
.container .subContainer:hover .inputTag:not(:disabled) {
  border-color: var(--color-brand-primary-default-new);
}


.container .inputBox .inputTag::placeholder,
.container .inputBox .inputTag:active::placeholder,
.container .inputBox .inputTag:hover::placeholder {
  color: var(--color-natural-black-light);
}

.container .inputBox .inputTag:disabled::placeholder {
  color: var(--color-natural-black-lighter-new);
}

.container .inputBox .inputTag:focus::placeholder {
  color: var(--color-natural-black-lighter)
}

.container .inputBox .inputTag:disabled {
  cursor: default;
  background-color: var(--color-natural-gray-lightest-1-new);
  color: var(--color-natural-black-lighter-new);
}

.container .inputBox .inputTag:not([value=""]) {
  padding: 8px;
}


/* ? status */
.container .inputBox .inputTag.success:active:not(:disabled),
.container .inputBox .inputTag.success:focus:not(:disabled),
.container .inputBox .inputTag.success:hover:not(:disabled),
.container .inputBox .inputTag.success:not(:disabled) {
  border-color: var(--color-feedback-success-default);
}

.container .inputBox .inputTag.error:active:not(:disabled),
.container .inputBox .inputTag.error:focus:not(:disabled),
.container .inputBox .inputTag.error:hover:not(:disabled),
.container .inputBox .inputTag.error:not(:disabled) {
  border-color: var(--color-feedback-error-default);
}

.container .inputBox .inputTag.warning:active:not(:disabled),
.container .inputBox .inputTag.warning:focus:not(:disabled),
.container .inputBox .inputTag.warning:hover:not(:disabled),
.container .inputBox .inputTag.warning:not(:disabled) {
  border-color: var(--color-feedback-warning-default);
}

/* ? suffix styles */
.container .subContainer:focus-within [class*="prefixSelect"],
.container:not([disabled]) .subContainer:hover [class*="prefixSelect"],
.container .subContainer:focus-within .suffixBox,
.container:not([disabled]) .subContainer:hover .suffixBox {
  border-color: var(--color-brand-primary-default-new);
  background-color: var(--color-brand-primary-lightest-2-new);
}

.container:not([disabled]) .subContainer.error:hover .suffixBox,
.container .subContainer.error .suffixBox,
.container:not([disabled]) .subContainer.error:hover [class*="prefixSelect"],
.container .subContainer.error [class*="prefixSelect"] {
  border-color: var(--color-feedback-error-default);
  background-color: rgba(255, 231, 227, 1);
}
.container:not([disabled]) .subContainer.success:hover .suffixBox,
.container .subContainer.success .suffixBox,
.container:not([disabled]) .subContainer.success:hover [class*="prefixSelect"],
.container .subContainer.success [class*="prefixSelect"] {
  border-color: var(--color-feedback-success-default);
  background-color: rgba(230, 246, 240, 1);
}
.container:not([disabled]) .subContainer.warning:hover .suffixBox,
.container .subContainer.warning .suffixBox,
.container:not([disabled]) .subContainer.warning:hover [class*="prefixSelect"],
.container .subContainer.warning [class*="prefixSelect"] {
  background-color: rgba(255, 245, 231, 1);
  border-color: var(--color-feedback-warning-default);
}

.container[disabled] .suffixBox {
  color: var(--color-natural-black-lighter-new);
}

/* ? only input styles */
.container .inputBox .clearIcon {
  cursor: pointer;
}

.container .inputBox .endIconBox {
  position: absolute;
  top: calc(50% - 12px);
  right: 8px;
}

.container .inputBox .startIconBox {
  position: absolute;
  top: calc(50% - 12px);
  left: 8px;
}

.container .inputBox .endIconBox .iconsDivider {
  height: 24px;
  width: 1px;
  margin-inline-end: 8px;
  margin-inline-start: 4px;
  background: linear-gradient(0deg, var(--color-natural-gray-lighter-1), var(--color-natural-gray-lighter-1)), var(--color-natural-black-lighter);
}

.container .inputBox input.inputTag.endIconStyle,
.container .inputBox input.inputTag.clearableStyle {
  padding-inline-end: 35px;
}

.container .inputBox input.inputTag.startIconStyle {
  padding-inline-start: 35px;
}

.container .inputBox input.inputTag.clearableStyle.endIconStyle {
  padding-inline-end: 70px;
}

/* ? remove the arrow of input type number */
.container .inputBox input.inputTag::-webkit-outer-spin-button,
.container .inputBox input.inputTag::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.container .inputBox input.inputTag[type=number] {
  -moz-appearance: textfield;
}

/* ? textarea Style */
.container .inputBox textarea {
  min-height: 90px;
  resize: vertical;
}

.container .inputBox textarea::-webkit-resizer {
  color: red;
  background: url("icons/linesgroup.svg") no-repeat;
  width: 6px;
  height: 6px;
  background-size: 80% 80%;
}

/* ? RTL styles */
[dir="rtl"] .container .inputBox .endIconBox {
  right: unset;
  left: 8px;
}

[dir="rtl"] .container .inputBox .startIconBox {
  right: 8px;
  left: unset;
}

[dir="rtl"] .container .inputBox textarea::-webkit-resizer {
  background: url("icons/rotatedlinesgroup.svg") no-repeat;
  background-size: 90% 90%;
}
