.textInput {
  max-width: 45px ;
  margin-top: -30px;
}

.textInput input{
 padding-left: 15px !important;
}

.textInput > div:last-child {
  display: none;
}

.soonBadge {
  border-radius: 44px;
  background-color: var(--brand-orange);
  color: var(--brand-white);
  padding: 6px 9px;
}
