.admin-header {
    padding: 1rem 1.875rem;
  
    a {
      text-decoration: none;
      color: var(--brand-text-black-opacity-50);
      padding-left: 2rem;
      padding-right: 4rem;
      &.is-active {
        color: var(--brand-text-black);
      }
    }
  
    .page-actions {
      padding: 0.5rem 2rem;
      border-right: 1px solid #DFE0EB;
      width: 3.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }