.modal {
  background: rgba(255, 255, 255, 0);
  position: fixed;
  z-index: 1000;
  width: 200%;
  height: 100%;
  top: 0;
  left: 0;
  animation: slideIn 0.3s ease-out forwards;
  display: flex;
  justify-content: flex-end;
}

.modalHeader {
  justify-content: flex-end;
  position: relative;
  padding: 24px 40px 0;
  display: flex;
}

.modalContent {
  padding: 0 40px;
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.modalFooter {
  padding: 0 40px;
  height: 80px;
  border-top: 1px solid var(--accessibility-slategray-400-new);
  position: relative;
}

.modalPaper {
  background: #fff;
  box-shadow: -5px 0px 20px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.closeBtn {
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.moveOut {
  animation: slideOut 0.3s ease-in forwards;
}

/* Variants */

.centerDialog {
  animation: fadeIn 0.3s ease-out forwards;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0;
  background-color: rgba(75, 97, 117, 0.9)
}

.centerDialog .modalPaper {
  /* margin: 0 auto; */
  min-height: 400px;
  height: fit-content;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.centerDialog .modalPaper > div {
  min-width: auto;
}

.centerDialog .modalContent {
  max-height: 85vh;
  overflow-y: auto;
  padding: 0;
}

.centerDialog.moveOut {
  animation: fadeOut 0.1s ease-in forwards;
}

/* .centerDialog .closeBtn {
  position: absolute;
  top: 30px;
  right: 30px;
} */

.centerDialog .modalHeader {
  padding: 1rem;
  background-color: var(--accessibility-slategray-50-new);
  border-bottom: 1px solid var(--accessibility-slategray-400-new);
  justify-content: space-between;
  align-items: center;
}

@keyframes slideIn {
  to {
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
  }
}

@keyframes slideOut {
  from {
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
  }
  to {
    background: rgba(255, 255, 255, 0);
    width: 200%;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* RTL */
[style*="rtl"] .modal,
[dir="rtl"] .modal {
  right: 0;
  left: auto;
}
