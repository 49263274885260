.hidden {
  display: none !important;
}

.avataContainer {
  cursor: pointer;
  min-height: 100px;
  background-color: var(--brand-atom-white-bg) !important;
}

.modalTemplateContent {
  padding: 16px !important;
}

.fieldInfoCard div input{
  border: none !important;
  color: var(--brand-text-dark-gray) !important;
  background-color: var(--brand-atom-white-bg) !important;
}

.fieldInfoContainer {
  position: relative;
  top: 0;
  left: 0;
}

.fieldInfoAction {
  position: absolute;
  top: 55%;
  right: 30px;
}

.cardSectionHeader {
  background-color: var(--brand-template-bg);
  padding: 16px 24px;
}
