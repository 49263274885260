.info-card {
  &.MuiPaper-root {
    padding: 1rem;
    background-color: var(--brand-card-bg);
    border-color: var(--brand-card-bg);
  }

  .info-card-title {
    padding-bottom: 0.5rem;
  }
}

.payment-info-card {
  &.MuiPaper-root {
    padding: 0.5rem;
    background-color: var(--brand-card-bg);
    border-color: var(--brand-card-bg);
  }
}
