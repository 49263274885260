.loginTemplateContainer {
  background-color: var(--brand-template-bg);
  height: 100vh;
}

.templateHeader {
  min-height: 270px;
  width: 100%;
  background-color: var(--brand-text-primary);
  padding-top: 80px;
  margin-bottom: -100px;
}