.container {
  overflow: auto;
}

.table {
  border: 0;
  width: 100%;
  border-spacing: 0;
  border-collapse: inherit;
  overflow-x: auto;
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 99;
}

.table thead tr th,
.table tbody tr td {
  padding: 16px;
  font-size: var(--font-en-bold-caption-12-fontSize);
  font-family: var(--font-en-bold-caption-12-fontFamily);
  font-weight: 500;
  line-height: 16px;
  border-right: 1px solid #e8ebee;
  border-bottom: 1px solid #d2d6de;
  border-left: 0;
  border-top: 0;
  text-align: inherit;
}

[dir="rtl"] .table thead tr th,
[dir="rtl"] .table tbody tr td {
  border-left: 1px solid #e8ebee;
  border-right: 0;
}

.table thead tr th {
  color: var(--color-natural-black-light);
  background: #fafbfc;

  border-bottom: 1px solid #e8ebee;
}

.table tbody tr td {
  color: var(--color-natural-black-default);
  position: relative;
}

.table tbody tr:hover {
  background-color: var(--color-brand-primary-lightest-1-new);
}

.table.disableHoverStyles tbody tr:hover {
  background-color: var(--color-natural-white-default-new);
}

.cellContent {
  display: flex;
  justify-content: space-between;
  font-family: var(--font-en-bold-caption-12-fontFamily);
}

.table tbody tr td:last-child,
.table thead tr th:last-child {
  border-right: 0;
}

[dir="rtl"] .table tbody tr td:last-child,
[dir="rtl"] .table thead tr th:last-child {
  border-left: 0;
}

.table tbody tr:last-child td {
  border-bottom: 0;
}

.table thead th.sortable {
  padding-right: 30px;
  cursor: pointer;
  background-image: url("img/arrow-up.svg"), url("img/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) 20px, calc(100% - 16px) 28px;
}

.table thead th.sortable.sortActive.sortDesc {
  background-image: url("img/arrow-up.svg"),
    url("img/arrow-down-active.svg");
}

.table thead th.sortable.sortActive.sortAsc {
  background-image: url("img/arrow-up-active.svg"),
    url("img/arrow-down.svg");
}

.errorRow td {
  background-color: #fcf8f8;
}

.hoverActions .actionsCell * {
  visibility: hidden;
}

.hoverActions tbody tr:hover .actionsCell * {
  visibility: visible;
}

.editIcon {
  cursor: pointer;
  color: var(--onboarding-blue-900);
}

.noWrap {
  white-space: nowrap;
}

/* variant = rounded */
.rounded {
  border: 1px solid #e8ebee;
  border-radius: 10px;
}

[dir="ltr"] .rounded thead tr:first-child th:first-child {
  border-top-left-radius: 11px;
}

[dir="ltr"] .rounded thead tr:first-child th:last-child {
  border-top-right-radius: 11px;
}

[dir="rtl"] .rounded thead tr:first-child th:first-child {
  border-top-right-radius: 11px;
}

[dir="rtl"] .rounded thead tr:first-child th:last-child {
  border-top-left-radius: 11px;
}
