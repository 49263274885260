.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  padding: 8px 16px;
  overflow: hidden;
  width: 100%;
}

.label {
  white-space: nowrap;
}

.divider {
  width: 16px;
  height: 100%;
  border-inline-end: 1px solid;
}

.btn.withStartIcon {
  padding-inline-start: 8px;
}

.btn.withEndIcon {
  padding-inline-end: 8px;
}

/* Sizes */
.btn.large {
  height: 48px;
}

.btn .divider {
  height: calc(100% + 16px);
  margin: -8px 0 ;
}

.iconBtn.large {
  padding: 12px ;
}

.btn.large > img,
.btn.large > svg {
  width: 24px;
  height: 24px;
}

/* medium */
.btn.medium {
  height: 40px;
}

.iconBtn.medium {
  padding: 10px ;
}

.btn.medium > img,
.btn.medium > svg {
  width: 20px;
  height: 20px;
}

/* small */
.btn.small {
  height: 32px;
}

.iconBtn.small {
  padding: 8px ;
}

/* disabled shred style */
.btn:disabled {
  pointer-events: none;
  cursor: default;
}

/* primary variant */
.btn.primary {
  background: var(--bg-color-button-primary);
  transition: 256ms;
}

.btn.primary > * {
  color: var(--text-color-button-primary);
}

.btn.primary:hover:not([disabled]) {
  transform: translateY(-2px);
}

.btn.primary:hover:not([disabled]):not(:active) {
  background: var(--bg-color-button-primary-dark);
}

.btn.primary:active {
  background-color: var(--bg-color-button-primary-darker);
}

.btn.primary:disabled {
  background: var(--brand-black-opacity-20);
  color: var(--brand-white);
}

.btn.primary:disabled .label {
  color: var(--brand-white);
}

.btn.primary .divider {
  border-color: var(--color-natural-white-lighter-new);
}

.btn.primary:disabled .divider {
  border-color: var(--color-natural-black-lighter-new);
}


/* secondary variant */
.btn.secondary {
  background-color: var(--bg-color-button-secondary);
  color: var(--text-color-button-secondary);
}

.btn.secondary:hover {
  background-color: var(--bg-color-button-secondary-dark);
}

.btn.secondary:active {
  background-color: var(--bg-color-button-secondary-darker);
}

.btn.secondary:disabled {
  color: var(--brand-white);
  background-color: var(--brand-black-opacity-20);
}

.btn.secondary:disabled .label {
  color: var(--brand-white);
}

.btn.secondary .divider {
  border-color: var(--color-natural-gray-light-new);
}


/* danger variant */
.btn.danger {
  background-color: var(--bg-color-button-danger);
}

.btn.danger > *{
  color: var(--text-color-button-danger);
}

.btn.danger .divider {
  display: none;
}

.btn.danger .label {
  color: var(--text-color-button-danger);
}

.btn.danger:hover {
  background-color: var(--bg-color-button-danger-dark);
}

.btn.danger:active {
  background-color: var(--bg-color-button-danger-darker);
}


.btn.danger:disabled {
  color: var(--brand-white);
  background-color: var(--brand-black-opacity-20);
}

.btn.danger:disabled .label {
  color: var(--brand-white);
}

/* ? Rtl Styles */
[dir="rtl"] .btn {
  direction: rtl;
}