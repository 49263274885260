#order-result-transition-popover {
  &.position-right {
    .MuiPaper-root {
      margin-left: 5.5rem;
    }
  }

  &.position-left {
    .MuiPaper-root {
      margin-left: -6.5rem;
    }
  }

  &.position-bottom {
    .MuiPaper-root {
      margin-top: 0.5rem;
    }
  }

  .MuiPaper-root {
    margin-top: -2rem;
    width: 12rem;
    border-radius: 0.5rem;
  }

  .MuiList-root {
    padding: 1rem;

    .MuiMenuItem-root {
      border-radius: 2px;
      padding: 0.5rem;
      margin-top: 0.5rem;
      background-color: var(--brand-template-bg-gray);
    }
  }
}

