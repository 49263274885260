.tagPill,
.tagNormal {
  position: relative;
  width: 90px;
  height: 24px;
}

.tagBg {
  position: absolute;
  height: 100%;
  width: 100%;
}

.tagNormal .tagBg {
    border-radius: 2px;
    opacity: 0.06;
}

.tagPill .tagBg {
    border-radius: 44px;
}

.tagText {
  text-transform: uppercase;
  font-family: var(--brand-font-family);
  font-size: 10px;
  font-weight: 800;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tagPill .tagText {
  color: var(--brand-white) !important;
}

