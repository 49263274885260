.header-filter {
  &.MuiInputBase-root.MuiInput-root {
    font-size: 13px;
    font-family: var(--brand-font-family);
    color: var(--brand-text-primary);

    &:before, &:after, &:hover {
      border: none !important;
    }
  }

  .MuiSelect-select.MuiInputBase-input.MuiInput-input {
    padding-top: 0.2rem;
    padding-bottom: 0;

    &:focus {
      background: none;
    }
  }

  .MuiSvgIcon-root.MuiSelect-icon {
    fill: var(--brand-text-primary);
  }
}

.header-filter-popover {
  &.MuiPaper-root.MuiMenu-paper {
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    margin-left: 2.5rem;
  }
}

.header-filter-options {
  &.MuiList-root.MuiMenu-list {
    min-width: 10rem;
    padding: 1rem;

    &:before {
      content: "Choose";
      font-family: var(--brand-font-family);
      font-size: 13px;
      color: #BBBDC9;
    }
  }

  .MuiMenuItem-root {
    margin: 0.5rem 0;
    padding: 0.5rem;
    background-color: var(--brand-template-bg-gray);
  }
}
