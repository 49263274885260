.radioBox {
  position: relative;
  outline: none;
  padding: 0;
}

.radioBox.disabled {
  opacity: 0.7;
}

.radioBox.disabled input[type="radio"]{
  cursor: default;
}

.radioBox .label {
  color: var(--accessibility-gray-800-new);
}

.radioBox input[type="radio"] {
  opacity: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconContainer > svg {
  width: 20px;
  height: 20px;
}

.largeOption {
  padding: 18px 24px;
  background-color: var(--brand-atom-white-bg);
  border-radius: 2px;
  cursor: pointer;
}

.largeOptionSelected {
  background-color: var(--bg-color-button-secondary);
}