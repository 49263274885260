.container {
  display: flex;
  flex-direction: column;
  color: var(--onboarding-grey-800);
}

.title {
  padding-bottom: 4px;
}

.progressBar {
  width: 248px;
  height: 8px;
  background-color: var(--onboarding-grey-400);
  border-radius: 4px;
  display: flex;
}

.progress {
  background-color: var(--onboarding-mics-green-900);
  border-radius: 4px;
}

.fullWidth, .fullWidth .progressBar {
  width: 100% !important;
}
