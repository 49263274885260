.infoCard {
    min-width: 700px;
}

.summaryCard {
    min-width: 350px;
}

.infoCardsContainer {
    padding: 16px 24px;
}

.infoLiCard {
    padding: 16px;
    background-color: var(--brand-card-bg) !important;
    border: none !important;
}

.hideItemActions {
    background: none !important;
    background-color: transparent !important;
}
