#user-row-menu-popover {
  .MuiPaper-root {
    margin-top: -2rem;
    margin-left: -6.5rem;
    width: 12rem;
    border-radius: 0.5rem;
  }
  .MuiList-root {
    padding: 1rem;
    .MuiMenuItem-root {
      border-radius: 2px;
      padding: 0.5rem;
      margin-top: 0.5rem;
      background-color: var(--brand-template-bg-gray);
      &.delete-user {
        background-color: transparent;
      }
    }
  }
}
