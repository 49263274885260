.search-input {
  background-color: var(--brand-template-bg);
  border-radius: 2rem;

  .MuiInputBase-input.MuiInput-input {
    padding: 0.5rem 1.5rem;
    width: 300px;

    ::placeholder {
      color: #90A0B7;
    }

    &:before {
      content: none;
    }
  }
}

#sort-filter,
#select-filter {
  &.MuiButton-root {
    color: var(--brand-text-black);
    text-transform: capitalize;
    font-family: var(--brand-font-family);

    &:hover {
      background-color: transparent;
    }
  }
}

#select-filter-menu {
  .MuiPaper-root {
    width: 16rem;
    margin-left: -11rem;
    border-radius: 0.5rem;
    overflow: visible;
  }

  &.ml-8 .MuiPaper-root {
    margin-left: -2rem;
  }

  .MuiList-root {
    padding: 0.75rem;
  }

  .select__control {
    background-color: var(--color-natural-gray-lightest-2-new);
    border-color: var(--color-natural-gray-lightest-2-new);
    margin-bottom: 0.5rem;
  }

  .MuiButton-root {
    color: var(--brand-text-black);
    text-transform: capitalize;
    font-family: var(--brand-font-family);
    font-weight: 700;
    margin-top: 1rem;

    &:hover {
      background-color: transparent;
    }

    &:last-child {
      color: var(--brand-text-primary);
    }
  }

  .react-datepicker-wrapper {
    margin-bottom: 0.5rem;

    input {
      background-color: var(--color-natural-gray-lightest-2-new);
      border-color: var(--color-natural-gray-lightest-2-new);
    }
  }
}
