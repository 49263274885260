.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.textInput input {
  font-weight: var(--font-en-medium-paragraph-14-fontWeight);
  font-size: var(--font-en-medium-paragraph-14-fontSize);
  font-family: var(--font-en-medium-paragraph-14-fontFamily);
  font-style: var(--font-en-medium-paragraph-14-fontStyle);
  line-height: var(--font-en-medium-paragraph-14-lineHeight);
  letter-spacing: var(--font-en-medium-paragraph-14-letterSpacing);
  color: var(--color-natural-black-default);
  caret-color: var(--color-natural-black-default);
}

.selectStartIcon {
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectOptionClass svg,
.selectStartIcon svg {
  width: 20px;
  height: 20px;
}

[dir="rtl"] [class*="select__placeholder"],
[dir="rtl"] [class*="select__input"] *,
[dir="rtl"] [class*="select__single-value"] {
  font-family: Bahij TheSansArabic !important;
}