.main-app {
  min-height: 100vh;
  min-width: 100vw;
}

.routes-container {
  width: 100vw;
  background-color: var(--brand-text-gray-lighter);
  padding: 24px;
}

.routes-container-margin {
  margin-left: 250px;
}
