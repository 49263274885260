/**
 * Do not edit directly
 * Generated on sunday, 23 Jan 2022 10:00:09 GMT
 */

:root {


  /* System Brand colors */
 --brand-dark-blue: #1E1E55; /* Navigation sidebar */
 --brand-dark-blue-opacity-50: rgba(75, 91, 188, 0.5); /* Navigation Item hover */
 --brand-white: #FFF;
 --brand-black: #000;
 --brand-divider-white: #F0F0F0;
 --brand-primary-border: linear-gradient(to bottom right,#5E72EB, #4CC9F0);
 --brand-template-bg: #FBFBFB;
 --brand-card-bg: #FDFDFD;
 --brand-template-light-gray: #E5E5E5;

  --brand-atom-white-bg: #FCFDFE;

 --brand-template-bg-gray: #F5F5F5;

 --brand-text-primary-opacity-04: rgba(94, 114, 235, 0.04);
 --brand-text-primary: #5E72EB;
 --brand-text-white: #fff;
 --brand-text-white-opacity-40: rgba(255, 255, 255, 0.2);
 --brand-text-gray-lighter: #D9DBE7;
 --brand-text-gray-light: #9FA2B4;
 --brand-text-gray: #7F818D;
 --brand-text-gray-2: #606F7B;
 --brand-text-dark-gray: #4B506D;
 --brand-text-dark-gray-opacity-40: rgba(75, 80, 109, 0.4);
 --brand-text-black: #252733;
 --brand-text-black-opacity-20: rgba(96, 111, 123, 0.2);
 --brand-text-black-opacity-40: rgba(96, 111, 123, 0.4);
 --brand-text-black-opacity-50: rgba(96, 111, 123, 0.5);
 --brand-black-opacity-20: rgba(0, 0, 0, 0.2);

 --brand-orange: #FE6C02;
 --brand-ready-text: #34C15D;
 --brand-confirm-text: #3F4462;
 --brand-process-text: #FEBD02;
 --brand-collect-text: #155AED;

 --brand-danger: #DA1E28;
 --brand-danger-bg: #FFF1F1;

 --brand-success: #309960;
 --brand-success-bg: #D9F4E6;

 --brand-warning: #FE6C02;
 --brand-warning-bg: rgba(254, 108, 2, 0.2);
 --brand-warning-bg-1: #fef8e5;

  /* ------------------------------------------------------- */

  --onboarding-blue-50: rgba(244, 241, 253, 1);
  --onboarding-blue-800: rgba(90, 66, 239, 1);
  --onboarding-blue-900: rgba(78, 53, 232, 1);
  --onboarding-slategrey-10: rgba(252, 253, 253, 1);
  --onboarding-slategrey-50: rgba(252, 253, 253, 1);
  --onboarding-slategrey-100: rgba(246, 248, 249, 1);
  --onboarding-slategrey-200: rgba(237, 240, 243, 1);
  --onboarding-slategrey-600: rgba(190, 198, 205, 1);
  --onboarding-slategrey-700: rgba(147, 160, 172, 1);
  --onboarding-slategrey-800: rgba(75, 97, 117, 1);
  --onboarding-slategrey-900: rgba(43, 69, 93, 1);
  --onboarding-grey-50: rgba(249, 249, 249, 1);
  --onboarding-grey-400: rgba(233, 233, 233, 1);
  --onboarding-grey-500: rgba(210, 210, 210, 1);
  --onboarding-grey-600: rgba(165, 165, 165, 1);
  --onboarding-grey-700: rgba(117, 117, 117, 1);
  --onboarding-grey-800: rgba(80, 80, 80, 1);
  --onboarding-grey-900: rgba(32, 32, 32, 1);
  --onboarding-mics-pink-900: rgba(219, 0, 131, 1);
  --onboarding-mics-green-50: rgba(232, 246, 235, 1);
  --onboarding-mics-green-900: rgba(46, 133, 64, 1);
  --onboarding-mics-red-50: rgba(252, 248, 248, 1);
  --onboarding-mics-red-100: rgba(249, 238, 238, 1);
  --onboarding-mics-red-900: rgba(169, 38, 38, 1);
  --onboarding-natural-black: rgba(0, 0, 0, 1);
  --onboarding-natural-white: rgba(255, 255, 255, 1);
  --color-brand-primary-darker-new: #0e2162ff;
  --color-brand-primary-dark-new: #153293ff;
  --color-brand-primary-default-new: #2353f5ff;
  --color-brand-primary-light-new: #7b98f9ff;
  --color-brand-primary-lighter-new: #a7bafbff;
  --color-brand-primary-lightest-2-new: #d3ddfdff;
  --color-brand-primary-lightest-1-new: #f4f6feff;
  --color-brand-accent-1-darker-new: #235c60ff;
  --color-brand-accent-1-dark-new: #42adb4ff;
  --color-brand-accent-1-default-new: #58e7f0ff;
  --color-brand-accent-1-light-new: #abf3f7ff;
  --color-brand-accent-1-lighter-new: #d5f9fbff;
  --color-brand-accent-1-lightest-2-new: #eefdfdff;
  --color-brand-accent-1-lightest-1-new: #f7fefeff;
  --color-brand-accent-2-darker-new: #7d442dff;
  --color-brand-accent-2-dark-new: #bf6844ff;
  --color-brand-accent-2-default-new: #ff8a5bff;
  --color-brand-accent-2-light-new: #ffc5adff;
  --color-brand-accent-2-lighter-new: #ffe2d6ff;
  --color-brand-accent-2-lightest-2-new: #fff3efff;
  --color-brand-accent-2-lightest-1-new: #fff9f7ff;
  --color-natural-white-default-new: #ffffffff;
  --color-natural-white-light-new: #b4b4bfff;
  --color-natural-white-lighter-new: #828295ff;
  --color-natural-white-lightest-2-new: #5d5d75ff;
  --color-natural-white-lightest-1-new: #44445fff;
  --color-natural-black-default-new: #06062bff;
  --color-natural-black-light-new: #51516bff;
  --color-natural-black-lighter-new: #c1c1caff;
  --color-natural-black-lightest-2-new: #e6e6eaff;
  --color-natural-black-lightest-1-new: #f3f3f4ff;
  --color-natural-gray-darker-0-new: #a5adbaff;
  --color-natural-gray-darker-1-new: #000000ad;
  --color-natural-gray-dark-new: #7c828cff;
  --color-natural-gray-default-new: #a5adbaff;
  --color-natural-gray-light-new: #d2d6ddff;
  --color-natural-gray-lighter-new: #e8ebeeff;
  --color-natural-gray-lightest-2-new: #f3f4f6ff;
  --color-natural-gray-lightest-1-new: #fafbfcff;
  --color-secondary-purple-darker-new: #472d78ff;
  --color-secondary-purple-dark-new: #6a44b3ff;
  --color-secondary-purple-default-new: #8d5befff;
  --color-secondary-purple-light-new: #c6adf7ff;
  --color-secondary-purple-lighter-new: #e3d6fbff;
  --color-secondary-turquoise-darker-new: #026e6eff;
  --color-secondary-turquoise-dark-new: #04a5a5ff;
  --color-secondary-turquoise-default-new: #17d0d0ff;
  --color-secondary-turquoise-light-new: #82eeeeff;
  --color-secondary-turquoise-lighter-new: #c0f6f6ff;
  --color-secondary-bittersweet-darker-new: #7b342cff;
  --color-secondary-bittersweet-dark-new: #b84e41ff;
  --color-secondary-bittersweet-default-new: #f66857ff;
  --color-secondary-bittersweet-light-new: #fab3abff;
  --color-secondary-bittersweet-lighter-new: #fdd9d5ff;
  --color-secondary-gold-darker-new: #674f16ff;
  --color-secondary-gold-dark-new: #9b7622ff;
  --color-secondary-gold-default-new: #ce9d2dff;
  --color-secondary-gold-light-new: #e7ce96ff;
  --color-secondary-gold-lighter-new: #f3e7caff;
  --color-secondary-mantis-darker-new: #436524ff;
  --color-secondary-mantis-dark-new: #649837ff;
  --color-secondary-mantis-default-new: #85c74aff;
  --color-secondary-mantis-light-new: #c2e5a4ff;
  --color-secondary-mantis-lighter-new: #e1f2d1ff;
  --color-secondary-orange-darker-new: #783d08ff;
  --color-secondary-orange-dark-new: #b45b0cff;
  --color-secondary-orange-default-new: #e7750eff;
  --color-secondary-orange-light-new: #f7bc87ff;
  --color-secondary-orange-lighter-new: #fbdec3ff;
  --color-secondary-overlay-overlay1-new: #cdcdd5ff;
  --color-feedback-success-darker-new: #0c4a31ff;
  --color-feedback-success-dark-new: #148156ff;
  --color-feedback-success-default-new: #1bac73ff;
  --color-feedback-success-light-new: #8dd6b9ff;
  --color-feedback-success-lighter-new: #c6eadcff;
  --color-feedback-success-lightest-2-new: #e6f6f0ff;
  --color-feedback-success-lightest-1-new: #f4fdf8ff;
  --color-feedback-warning-darker-new: #704607ff;
  --color-feedback-warning-dark-new: #bf780bff;
  --color-feedback-warning-default-new: #ffa00fff;
  --color-feedback-warning-light-new: #ffcf87ff;
  --color-feedback-warning-lighter-new: #ffe7c3ff;
  --color-feedback-warning-lightest-2-new: #fff5e7ff;
  --color-feedback-warning-lightest-1-new: #fffaf3ff;
  --color-feedback-error-darker-new: #8a2518ff;
  --color-feedback-error-dark-new: #bf3321ff;
  --color-feedback-error-default-new: #ff442cff;
  --color-feedback-error-light-new: #ffa295ff;
  --color-feedback-error-lighter-new: #ffd0caff;
  --color-feedback-error-lightest-2-new: #ffe7e3ff;
  --color-feedback-error-lightest-1-new: #fff6f4ff;
  --color-feedback-info-darker-new: #075767ff;
  --color-feedback-info-dark-new: #0d98b3ff;
  --color-feedback-info-default-new: #11caefff;
  --color-feedback-info-light-new: #88e5f7ff;
  --color-feedback-info-lighter-new: #c4f2fbff;
  --color-feedback-info-lightest-2-new: #e7fafdff;
  --color-feedback-info-lightest-1-new: #f3fcfeff;
  /* old colors  */
  --color-brand-primary-darker-0: rgba(31, 29, 221, 1);
  --color-brand-primary-darker-1: rgba(0, 0, 0, 0.5);
  --color-brand-primary-dark-0: rgba(31, 29, 221, 1);
  --color-brand-primary-dark-1: rgba(0, 0, 0, 0.25);
  --color-brand-primary-default: rgba(31, 29, 221, 1);
  --color-brand-primary-light-0: rgba(31, 29, 221, 1);
  --color-brand-primary-light-1: rgba(255, 255, 255, 0.5);
  --color-brand-primary-lighter-0: rgba(31, 29, 221, 1);
  --color-brand-primary-lighter-1: rgba(255, 255, 255, 0.75);
  --color-brand-primary-lightest-2-0: rgba(31, 29, 221, 1);
  --color-brand-primary-lightest-2-1: rgba(255, 255, 255, 0.9);
  --color-brand-primary-lightest-1-0: rgba(31, 29, 221, 1);
  --color-brand-primary-lightest-1-1: rgba(255, 255, 255, 0.95);
  --color-brand-accent-1-darker-0: rgba(88, 231, 240, 1);
  --color-brand-accent-1-darker-1: rgba(0, 0, 0, 0.5);
  --color-brand-accent-1-dark-0: rgba(88, 231, 240, 1);
  --color-brand-accent-1-dark-1: rgba(0, 0, 0, 0.25);
  --color-brand-accent-1-default: rgba(88, 231, 240, 1);
  --color-brand-accent-1-light-0: rgba(88, 231, 240, 1);
  --color-brand-accent-1-light-1: rgba(255, 255, 255, 0.5);
  --color-brand-accent-1-lighter-0: rgba(88, 231, 240, 1);
  --color-brand-accent-1-lighter-1: rgba(255, 255, 255, 0.5);
  --color-brand-accent-1-lightest-2-0: rgba(88, 231, 240, 1);
  --color-brand-accent-1-lightest-2-1: rgba(255, 255, 255, 0.9);
  --color-brand-accent-1-lightest-1-0: rgba(88, 231, 240, 1);
  --color-brand-accent-1-lightest-1-1: rgba(255, 255, 255, 0.95);
  --color-brand-accent-2-darker-0: rgba(255, 138, 91, 1);
  --color-brand-accent-2-darker-1: rgba(0, 0, 0, 0.5);
  --color-brand-accent-2-dark-0: rgba(255, 138, 91, 1);
  --color-brand-accent-2-dark-1: rgba(0, 0, 0, 0.25);
  --color-brand-accent-2-default: rgba(255, 138, 91, 1);
  --color-brand-accent-2-light-0: rgba(255, 138, 91, 1);
  --color-brand-accent-2-light-1: rgba(255, 255, 255, 0.5);
  --color-brand-accent-2-lighter-0: rgba(255, 138, 91, 1);
  --color-brand-accent-2-lighter-1: rgba(255, 255, 255, 0.75);
  --color-brand-accent-2-lightest-2-0: rgba(255, 138, 91, 1);
  --color-brand-accent-2-lightest-2-1: rgba(255, 255, 255, 0.9);
  --color-brand-accent-2-lightest-1-0: rgba(255, 138, 91, 1);
  --color-brand-accent-2-lightest-1-1: rgba(255, 255, 255, 0.95);
  --color-natural-white-default: rgba(255, 255, 255, 1); /* (Default text) */
  --color-natural-white-light: rgba(255, 255, 255, 0.7); /* (Secondary text) */
  --color-natural-white-lighter: rgba(255, 255, 255, 0.5); /* (Disabled text) */
  --color-natural-white-lightest-2: rgba(
    255,
    255,
    255,
    0.35
  ); /* (Default border) */
  --color-natural-white-lightest-1: rgba(
    255,
    255,
    255,
    0.25
  ); /* (Default fill) */
  --color-natural-black-default: rgba(6, 6, 43, 1); /* (Default text) */
  --color-natural-black-light: rgba(6, 6, 43, 0.5); /* (Secondary text) */
  --color-natural-black-lighter: rgba(6, 6, 43, 0.25); /* (Disabled text) */
  --color-natural-black-lightest-2: rgba(6, 6, 43, 0.1); /* (Default border) */
  --color-natural-black-lightest-1: rgba(6, 6, 43, 0.05); /* (Default fill) */
  --color-natural-gray-darker-0: rgba(165, 173, 186, 1);
  --color-natural-gray-darker-2: rgba(0, 0, 0, 0.68);
  --color-natural-gray-darker-1: rgba(0, 0, 0, 0.5);
  --color-natural-gray-dark-0: rgba(165, 173, 186, 1);
  --color-natural-gray-dark-1: rgba(0, 0, 0, 0.25);
  --color-natural-gray-default: rgba(165, 173, 186, 1);
  --color-natural-gray-light-0: rgba(165, 173, 186, 1);
  --color-natural-gray-light-1: rgba(255, 255, 255, 0.5);
  --color-natural-gray-lighter-0: rgba(165, 173, 186, 1);
  --color-natural-gray-lighter-1: rgba(255, 255, 255, 0.75);
  --color-natural-gray-lightest-2-0: rgba(165, 173, 186, 1);
  --color-natural-gray-lightest-2-1: rgba(255, 255, 255, 0.9);
  --color-natural-gray-lightest-1-0: rgba(165, 173, 186, 1);
  --color-natural-gray-lightest-1-1: rgba(255, 255, 255, 0.95);
  --color-secondary-purple-darker-0: rgba(141, 91, 239, 1);
  --color-secondary-purple-darker-1: rgba(0, 0, 0, 0.5);
  --color-secondary-purple-dark-0: rgba(141, 91, 239, 1);
  --color-secondary-purple-dark-1: rgba(0, 0, 0, 0.25);
  --color-secondary-purple-default: rgba(141, 91, 239, 1);
  --color-secondary-purple-light-0: rgba(141, 91, 239, 1);
  --color-secondary-purple-light-1: rgba(255, 255, 255, 0.5);
  --color-secondary-purple-lighter-0: rgba(141, 91, 239, 1);
  --color-secondary-purple-lighter-1: rgba(255, 255, 255, 0.75);
  --color-secondary-turquoise-darker-0: rgba(5, 220, 220, 1);
  --color-secondary-turquoise-darker-1: rgba(0, 0, 0, 0.5);
  --color-secondary-turquoise-dark-0: rgba(5, 220, 220, 1);
  --color-secondary-turquoise-dark-1: rgba(0, 0, 0, 0.25);
  --color-secondary-turquoise-default: rgba(23, 208, 208, 1);
  --color-secondary-turquoise-light-0: rgba(5, 220, 220, 1);
  --color-secondary-turquoise-light-1: rgba(255, 255, 255, 0.5);
  --color-secondary-turquoise-lighter-0: rgba(5, 220, 220, 1);
  --color-secondary-turquoise-lighter-1: rgba(255, 255, 255, 0.75);
  --color-secondary-bittersweet-darker-0: rgba(246, 104, 87, 1);
  --color-secondary-bittersweet-darker-1: rgba(0, 0, 0, 0.5);
  --color-secondary-bittersweet-dark-0: rgba(246, 104, 87, 1);
  --color-secondary-bittersweet-dark-1: rgba(0, 0, 0, 0.25);
  --color-secondary-bittersweet-default: rgba(246, 104, 87, 1);
  --color-secondary-bittersweet-light-0: rgba(246, 104, 87, 1);
  --color-secondary-bittersweet-light-1: rgba(255, 255, 255, 0.5);
  --color-secondary-bittersweet-lighter-0: rgba(246, 104, 87, 1);
  --color-secondary-bittersweet-lighter-1: rgba(255, 255, 255, 0.75);
  --color-secondary-gold-darker-0: rgba(206, 157, 45, 1);
  --color-secondary-gold-darker-1: rgba(0, 0, 0, 0.5);
  --color-secondary-gold-dark-0: rgba(206, 157, 45, 1);
  --color-secondary-gold-dark-1: rgba(0, 0, 0, 0.25);
  --color-secondary-gold-default: rgba(206, 157, 45, 1);
  --color-secondary-gold-light-0: rgba(206, 157, 45, 1);
  --color-secondary-gold-light-1: rgba(255, 255, 255, 0.5);
  --color-secondary-gold-lighter-0: rgba(206, 157, 45, 1);
  --color-secondary-gold-lighter-1: rgba(255, 255, 255, 0.75);
  --color-secondary-mantis-darker-0: rgba(134, 202, 73, 1);
  --color-secondary-mantis-darker-1: rgba(0, 0, 0, 0.5);
  --color-secondary-mantis-dark-0: rgba(134, 202, 73, 1);
  --color-secondary-mantis-dark-1: rgba(0, 0, 0, 0.25);
  --color-secondary-mantis-default: rgba(133, 199, 74, 1);
  --color-secondary-mantis-light-0: rgba(134, 202, 73, 1);
  --color-secondary-mantis-light-1: rgba(255, 255, 255, 0.5);
  --color-secondary-mantis-lighter-0: rgba(134, 202, 73, 1);
  --color-secondary-mantis-lighter-1: rgba(255, 255, 255, 0.75);
  --color-secondary-orange-darker-0: rgba(240, 122, 16, 1);
  --color-secondary-orange-darker-1: rgba(0, 0, 0, 0.5);
  --color-secondary-orange-dark-0: rgba(240, 122, 16, 1);
  --color-secondary-orange-dark-1: rgba(0, 0, 0, 0.25);
  --color-secondary-orange-default: rgba(231, 117, 14, 1);
  --color-secondary-orange-light-0: rgba(240, 122, 16, 1);
  --color-secondary-orange-light-1: rgba(255, 255, 255, 0.5);
  --color-secondary-orange-lighter-0: rgba(240, 122, 16, 1);
  --color-secondary-orange-lighter-1: rgba(255, 255, 255, 0.75);
  --color-secondary-overlay-overlay1: rgba(6, 6, 43, 0.8);
  --color-feedback-success-darker-0: rgba(27, 172, 115, 1);
  --color-feedback-success-darker-1: rgba(0, 0, 0, 0.5);
  --color-feedback-success-dark-0: rgba(27, 172, 115, 1);
  --color-feedback-success-dark-1: rgba(0, 0, 0, 0.25);
  --color-feedback-success-default: rgba(27, 172, 115, 1);
  --color-feedback-success-light-0: rgba(27, 172, 115, 1);
  --color-feedback-success-light-1: rgba(255, 255, 255, 0.5);
  --color-feedback-success-lighter-0: rgba(27, 172, 115, 1);
  --color-feedback-success-lighter-1: rgba(255, 255, 255, 0.75);
  --color-feedback-success-lightest-2-0: rgba(27, 172, 115, 1);
  --color-feedback-success-lightest-2-1: rgba(255, 255, 255, 0.9);
  --color-feedback-success-lightest-1-0: rgba(27, 172, 115, 1);
  --color-feedback-success-lightest-1-1: rgba(255, 255, 255, 0.95);
  --color-feedback-warning-darker-0: rgba(255, 160, 15, 1);
  --color-feedback-warning-darker-1: rgba(0, 0, 0, 0.5);
  --color-feedback-warning-dark-0: rgba(255, 160, 15, 1);
  --color-feedback-warning-dark-1: rgba(0, 0, 0, 0.25);
  --color-feedback-warning-default: rgba(255, 160, 15, 1);
  --color-feedback-warning-light-0: rgba(255, 160, 15, 1);
  --color-feedback-warning-light-1: rgba(255, 255, 255, 0.5);
  --color-feedback-warning-lighter-0: rgba(255, 160, 15, 1);
  --color-feedback-warning-lighter-1: rgba(255, 255, 255, 0.75);
  --color-feedback-warning-lightest-2-0: rgba(255, 160, 15, 1);
  --color-feedback-warning-lightest-2-1: rgba(255, 255, 255, 0.9);
  --color-feedback-warning-lightest-1-0: rgba(255, 160, 15, 1);
  --color-feedback-warning-lightest-1-1: rgba(255, 255, 255, 0.95);
  --color-feedback-error-darker-0: rgba(255, 68, 44, 1);
  --color-feedback-error-darker-1: rgba(0, 0, 0, 0.5);
  --color-feedback-error-dark-0: rgba(255, 68, 44, 1);
  --color-feedback-error-dark-1: rgba(0, 0, 0, 0.25);
  --color-feedback-error-default: rgba(255, 68, 44, 1);
  --color-feedback-error-light-0: rgba(255, 68, 44, 1);
  --color-feedback-error-light-1: rgba(255, 255, 255, 0.5);
  --color-feedback-error-lighter-0: rgba(255, 68, 44, 1);
  --color-feedback-error-lighter-1: rgba(255, 255, 255, 0.75);
  --color-feedback-error-lightest-2-0: rgba(255, 68, 44, 1);
  --color-feedback-error-lightest-2-1: rgba(255, 255, 255, 0.9);
  --color-feedback-error-lightest-1-0: rgba(255, 68, 44, 1);
  --color-feedback-error-lightest-1-1: rgba(255, 255, 255, 0.95);
  --color-feedback-info-darker-0: rgba(0, 176, 211, 1);
  --color-feedback-info-darker-1: rgba(0, 0, 0, 0.5);
  --color-feedback-info-dark-0: rgba(0, 176, 211, 1);
  --color-feedback-info-dark-1: rgba(0, 0, 0, 0.25);
  --color-feedback-info-default: rgba(0, 176, 211, 1);
  --color-feedback-info-light-0: rgba(0, 176, 211, 1);
  --color-feedback-info-light-1: rgba(255, 255, 255, 0.5);
  --color-feedback-info-lighter-0: rgba(0, 176, 211, 1);
  --color-feedback-info-lighter-1: rgba(255, 255, 255, 0.75);
  --color-feedback-info-lightest-2-0: rgba(0, 176, 211, 1);
  --color-feedback-info-lightest-2-1: rgba(255, 255, 255, 0.9);
  --color-feedback-info-lightest-1-0: rgba(0, 176, 211, 1);
  --color-feedback-info-lightest-1-1: rgba(255, 255, 255, 0.95);

  /* Button Colors */
   --bg-color-button-primary: linear-gradient(90deg, #5E72EB 0.46%, #4CC9F0 100%);
   --bg-color-button-primary-dark: linear-gradient(90deg, #5465D1 0.46%, #44B3D6 100%);
   --bg-color-button-primary-darker: linear-gradient(90deg, #5465D1 0.8%, #44B3D6 100%);

   --bg-color-button-secondary: rgba(94, 114, 235, 0.04);
   --bg-color-button-secondary-dark: rgba(94, 114, 235, 0.08);
   --bg-color-button-secondary-darker: rgba(94, 114, 235, 0.2);

   --bg-color-button-danger:  rgba(250, 65, 65, 0.04);
   --bg-color-button-danger-dark:  rgba(250, 65, 65, 0.08);
   --bg-color-button-danger-darker:  rgba(250, 65, 65, 0.2);

   --text-color-button-primary: #FFF;
   --text-color-button-secondary:  #5E72EB;
   --text-color-button-danger:  #FA4141;

  --grid-device-column-fluid-extra-large-pattern: columns;
  --grid-device-column-fluid-extra-large-gutterSize: 24;
  --grid-device-column-fluid-extra-large-alignment: stretch;
  --grid-device-column-fluid-extra-large-count: 12;
  --grid-device-column-fluid-extra-large-offset: 24;
  --grid-device-column-fluid-large-pattern: columns;
  --grid-device-column-fluid-large-gutterSize: 24;
  --grid-device-column-fluid-large-alignment: stretch;
  --grid-device-column-fluid-large-count: 8;
  --grid-device-column-fluid-large-offset: 24;
  --grid-device-column-fluid-medium-pattern: columns;
  --grid-device-column-fluid-medium-gutterSize: 16;
  --grid-device-column-fluid-medium-alignment: stretch;
  --grid-device-column-fluid-medium-count: 4;
  --grid-device-column-fluid-medium-offset: 16;
  --grid-device-column-fluid-small-pattern: columns;
  --grid-device-column-fluid-small-gutterSize: 16;
  --grid-device-column-fluid-small-alignment: stretch;
  --grid-device-column-fluid-small-count: 2;
  --grid-device-column-fluid-small-offset: 16;
  --grid-device-column-fixed-extra-large-pattern: columns;
  --grid-device-column-fixed-extra-large-sectionSize: 74;
  --grid-device-column-fixed-extra-large-gutterSize: 24;
  --grid-device-column-fixed-extra-large-alignment: center;
  --grid-device-column-fixed-extra-large-count: 12;
  --grid-device-column-fixed-large-pattern: columns;
  --grid-device-column-fixed-large-sectionSize: 85;
  --grid-device-column-fixed-large-gutterSize: 24;
  --grid-device-column-fixed-large-alignment: center;
  --grid-device-column-fixed-large-count: 8;
  --grid-device-column-fixed-medium-pattern: columns;
  --grid-device-column-fixed-medium-sectionSize: 130;
  --grid-device-column-fixed-medium-gutterSize: 16;
  --grid-device-column-fixed-medium-alignment: center;
  --grid-device-column-fixed-medium-count: 4;
  --grid-device-column-fixed-small-pattern: columns;
  --grid-device-column-fixed-small-sectionSize: 163;
  --grid-device-column-fixed-small-gutterSize: 16;
  --grid-device-column-fixed-small-alignment: center;
  --grid-device-column-fixed-small-count: 2;
  --grid-device-modular-fluid-extra-large-0-pattern: columns;
  --grid-device-modular-fluid-extra-large-0-gutterSize: 24;
  --grid-device-modular-fluid-extra-large-0-alignment: stretch;
  --grid-device-modular-fluid-extra-large-0-count: 12;
  --grid-device-modular-fluid-extra-large-0-offset: 24;
  --grid-device-modular-fluid-extra-large-1-pattern: rows;
  --grid-device-modular-fluid-extra-large-1-sectionSize: 64;
  --grid-device-modular-fluid-extra-large-1-gutterSize: 24;
  --grid-device-modular-fluid-extra-large-1-alignment: min;
  --grid-device-modular-fluid-extra-large-1-count: auto;
  --grid-device-modular-fluid-extra-large-1-offset: 24;
  --grid-device-modular-fluid-large-0-pattern: columns;
  --grid-device-modular-fluid-large-0-gutterSize: 24;
  --grid-device-modular-fluid-large-0-alignment: stretch;
  --grid-device-modular-fluid-large-0-count: 8;
  --grid-device-modular-fluid-large-0-offset: 24;
  --grid-device-modular-fluid-large-1-pattern: rows;
  --grid-device-modular-fluid-large-1-sectionSize: 64;
  --grid-device-modular-fluid-large-1-gutterSize: 24;
  --grid-device-modular-fluid-large-1-alignment: min;
  --grid-device-modular-fluid-large-1-count: auto;
  --grid-device-modular-fluid-large-1-offset: 24;
  --grid-device-modular-fluid-medium-0-pattern: columns;
  --grid-device-modular-fluid-medium-0-gutterSize: 16;
  --grid-device-modular-fluid-medium-0-alignment: stretch;
  --grid-device-modular-fluid-medium-0-count: 4;
  --grid-device-modular-fluid-medium-0-offset: 16;
  --grid-device-modular-fluid-medium-1-pattern: rows;
  --grid-device-modular-fluid-medium-1-sectionSize: 64;
  --grid-device-modular-fluid-medium-1-gutterSize: 16;
  --grid-device-modular-fluid-medium-1-alignment: min;
  --grid-device-modular-fluid-medium-1-count: auto;
  --grid-device-modular-fluid-medium-1-offset: 16;
  --grid-device-modular-fluid-small-0-pattern: columns;
  --grid-device-modular-fluid-small-0-gutterSize: 16;
  --grid-device-modular-fluid-small-0-alignment: stretch;
  --grid-device-modular-fluid-small-0-count: 2;
  --grid-device-modular-fluid-small-0-offset: 16;
  --grid-device-modular-fluid-small-1-pattern: rows;
  --grid-device-modular-fluid-small-1-sectionSize: 64;
  --grid-device-modular-fluid-small-1-gutterSize: 16;
  --grid-device-modular-fluid-small-1-alignment: min;
  --grid-device-modular-fluid-small-1-count: auto;
  --grid-device-modular-fluid-small-1-offset: 16;
  --grid-device-modular-fixed-extra-large-0-pattern: columns;
  --grid-device-modular-fixed-extra-large-0-sectionSize: 74;
  --grid-device-modular-fixed-extra-large-0-gutterSize: 24;
  --grid-device-modular-fixed-extra-large-0-alignment: center;
  --grid-device-modular-fixed-extra-large-0-count: 12;
  --grid-device-modular-fixed-extra-large-1-pattern: rows;
  --grid-device-modular-fixed-extra-large-1-sectionSize: 64;
  --grid-device-modular-fixed-extra-large-1-gutterSize: 24;
  --grid-device-modular-fixed-extra-large-1-alignment: min;
  --grid-device-modular-fixed-extra-large-1-count: auto;
  --grid-device-modular-fixed-extra-large-1-offset: 24;
  --grid-device-modular-fixed-large-0-pattern: columns;
  --grid-device-modular-fixed-large-0-sectionSize: 85;
  --grid-device-modular-fixed-large-0-gutterSize: 24;
  --grid-device-modular-fixed-large-0-alignment: center;
  --grid-device-modular-fixed-large-0-count: 8;
  --grid-device-modular-fixed-large-1-pattern: rows;
  --grid-device-modular-fixed-large-1-sectionSize: 64;
  --grid-device-modular-fixed-large-1-gutterSize: 24;
  --grid-device-modular-fixed-large-1-alignment: min;
  --grid-device-modular-fixed-large-1-count: auto;
  --grid-device-modular-fixed-large-1-offset: 24;
  --grid-device-modular-fixed-medium-0-pattern: columns;
  --grid-device-modular-fixed-medium-0-sectionSize: 130;
  --grid-device-modular-fixed-medium-0-gutterSize: 16;
  --grid-device-modular-fixed-medium-0-alignment: center;
  --grid-device-modular-fixed-medium-0-count: 4;
  --grid-device-modular-fixed-medium-1-pattern: rows;
  --grid-device-modular-fixed-medium-1-sectionSize: 64;
  --grid-device-modular-fixed-medium-1-gutterSize: 16;
  --grid-device-modular-fixed-medium-1-alignment: min;
  --grid-device-modular-fixed-medium-1-count: auto;
  --grid-device-modular-fixed-medium-1-offset: 16;
  --grid-device-modular-fixed-small-0-pattern: columns;
  --grid-device-modular-fixed-small-0-sectionSize: 162;
  --grid-device-modular-fixed-small-0-gutterSize: 16;
  --grid-device-modular-fixed-small-0-alignment: center;
  --grid-device-modular-fixed-small-0-count: 2;
  --grid-device-modular-fixed-small-1-pattern: rows;
  --grid-device-modular-fixed-small-1-sectionSize: 64;
  --grid-device-modular-fixed-small-1-gutterSize: 16;
  --grid-device-modular-fixed-small-1-alignment: min;
  --grid-device-modular-fixed-small-1-count: auto;
  --grid-device-modular-fixed-small-1-offset: 16;
  --grid-general-baseline-pattern: rows;
  --grid-general-baseline-sectionSize: 8;
  --grid-general-baseline-gutterSize: 8;
  --grid-general-baseline-alignment: min;
  --grid-general-baseline-count: auto;
  --grid-general-baseline-offset: 8;
  --grid-general-manuscript-pattern: columns;
  --grid-general-manuscript-gutterSize: 24;
  --grid-general-manuscript-alignment: stretch;
  --grid-general-manuscript-count: 1;
  --grid-general-manuscript-offset: 24;
  --grid-container-modular-fluid-large-0-pattern: columns;
  --grid-container-modular-fluid-large-0-gutterSize: 16;
  --grid-container-modular-fluid-large-0-alignment: stretch;
  --grid-container-modular-fluid-large-0-count: 8;
  --grid-container-modular-fluid-large-0-offset: 24;
  --grid-container-modular-fluid-large-1-pattern: rows;
  --grid-container-modular-fluid-large-1-gutterSize: 24;
  --grid-container-modular-fluid-large-1-alignment: stretch;
  --grid-container-modular-fluid-large-1-count: 1;
  --grid-container-modular-fluid-large-1-offset: 24;
  --grid-container-modular-fluid-medium-0-pattern: columns;
  --grid-container-modular-fluid-medium-0-gutterSize: 16;
  --grid-container-modular-fluid-medium-0-alignment: stretch;
  --grid-container-modular-fluid-medium-0-count: 8;
  --grid-container-modular-fluid-medium-0-offset: 16;
  --grid-container-modular-fluid-medium-1-pattern: rows;
  --grid-container-modular-fluid-medium-1-gutterSize: 16;
  --grid-container-modular-fluid-medium-1-alignment: stretch;
  --grid-container-modular-fluid-medium-1-count: 1;
  --grid-container-modular-fluid-medium-1-offset: 16;
  --grid-container-modular-fluid-small-0-pattern: columns;
  --grid-container-modular-fluid-small-0-gutterSize: 8;
  --grid-container-modular-fluid-small-0-alignment: stretch;
  --grid-container-modular-fluid-small-0-count: 8;
  --grid-container-modular-fluid-small-0-offset: 8;
  --grid-container-modular-fluid-small-1-pattern: rows;
  --grid-container-modular-fluid-small-1-gutterSize: 8;
  --grid-container-modular-fluid-small-1-alignment: stretch;
  --grid-container-modular-fluid-small-1-count: 1;
  --grid-container-modular-fluid-small-1-offset: 8;
  --grid-container-padding-large-0-pattern: columns;
  --grid-container-padding-large-0-gutterSize: 0;
  --grid-container-padding-large-0-alignment: stretch;
  --grid-container-padding-large-0-count: 1;
  --grid-container-padding-large-0-offset: 24;
  --grid-container-padding-large-1-pattern: rows;
  --grid-container-padding-large-1-gutterSize: 0;
  --grid-container-padding-large-1-alignment: stretch;
  --grid-container-padding-large-1-count: 1;
  --grid-container-padding-large-1-offset: 24;
  --grid-container-padding-medium-0-pattern: columns;
  --grid-container-padding-medium-0-gutterSize: 0;
  --grid-container-padding-medium-0-alignment: stretch;
  --grid-container-padding-medium-0-count: 1;
  --grid-container-padding-medium-0-offset: 16;
  --grid-container-padding-medium-1-pattern: rows;
  --grid-container-padding-medium-1-gutterSize: 0;
  --grid-container-padding-medium-1-alignment: stretch;
  --grid-container-padding-medium-1-count: 1;
  --grid-container-padding-medium-1-offset: 16;
  --grid-container-padding-small-0-pattern: columns;
  --grid-container-padding-small-0-gutterSize: 0;
  --grid-container-padding-small-0-alignment: stretch;
  --grid-container-padding-small-0-count: 1;
  --grid-container-padding-small-0-offset: 8;
  --grid-container-padding-small-1-pattern: rows;
  --grid-container-padding-small-1-gutterSize: 0;
  --grid-container-padding-small-1-alignment: stretch;
  --grid-container-padding-small-1-count: 1;
  --grid-container-padding-small-1-offset: 8;
  --font-en-bold-display-64-fontSize: 64px;
  --font-en-bold-display-64-textDecoration: none;
  --font-en-bold-display-64-fontFamily: Nunito;
  --font-en-bold-display-64-fontWeight: 700;
  --font-en-bold-display-64-fontStyle: normal;
  --font-en-bold-display-64-fontStretch: normal;
  --font-en-bold-display-64-fontStyleOld: Bold;
  --font-en-bold-display-64-letterSpacing: normal;
  --font-en-bold-display-64-lineHeight: 96px;
  --font-en-bold-display-64-paragraphIndent: 0;
  --font-en-bold-display-64-paragraphSpacing: 0;
  --font-en-bold-display-64-textCase: none;
  --font-en-bold-display-50-fontSize: 50px;
  --font-en-bold-display-50-textDecoration: none;
  --font-en-bold-display-50-fontFamily: Nunito;
  --font-en-bold-display-50-fontWeight: 700;
  --font-en-bold-display-50-fontStyle: normal;
  --font-en-bold-display-50-fontStretch: normal;
  --font-en-bold-display-50-fontStyleOld: Bold;
  --font-en-bold-display-50-letterSpacing: normal;
  --font-en-bold-display-50-lineHeight: 72px;
  --font-en-bold-display-50-paragraphIndent: 0;
  --font-en-bold-display-50-paragraphSpacing: 0;
  --font-en-bold-display-50-textCase: none;
  --font-en-bold-display-40-fontSize: 40px;
  --font-en-bold-display-40-textDecoration: none;
  --font-en-bold-display-40-fontFamily: Nunito;
  --font-en-bold-display-40-fontWeight: 700;
  --font-en-bold-display-40-fontStyle: normal;
  --font-en-bold-display-40-fontStretch: normal;
  --font-en-bold-display-40-fontStyleOld: Bold;
  --font-en-bold-display-40-letterSpacing: normal;
  --font-en-bold-display-40-lineHeight: 54px;
  --font-en-bold-display-40-paragraphIndent: 0;
  --font-en-bold-display-40-paragraphSpacing: 0;
  --font-en-bold-display-40-textCase: none;
  --font-en-bold-display-36-fontSize: 36px;
  --font-en-bold-display-36-textDecoration: none;
  --font-en-bold-display-36-fontFamily: Nunito;
  --font-en-bold-display-36-fontWeight: 700;
  --font-en-bold-display-36-fontStyle: normal;
  --font-en-bold-display-36-fontStretch: normal;
  --font-en-bold-display-36-fontStyleOld: Bold;
  --font-en-bold-display-36-letterSpacing: normal;
  --font-en-bold-display-36-lineHeight: 48px;
  --font-en-bold-display-36-paragraphIndent: 0;
  --font-en-bold-display-36-paragraphSpacing: 0;
  --font-en-bold-display-36-textCase: none;
  --font-en-bold-headline-24-fontSize: 24px;
  --font-en-bold-headline-24-textDecoration: none;
  --font-en-bold-headline-24-fontFamily: Nunito;
  --font-en-bold-headline-24-fontWeight: 700;
  --font-en-bold-headline-24-fontStyle: normal;
  --font-en-bold-headline-24-fontStretch: normal;
  --font-en-bold-headline-24-fontStyleOld: Bold;
  --font-en-bold-headline-24-letterSpacing: normal;
  --font-en-bold-headline-24-lineHeight: 32px;
  --font-en-bold-headline-24-paragraphIndent: 0;
  --font-en-bold-headline-24-paragraphSpacing: 0;
  --font-en-bold-headline-24-textCase: none;
  --font-en-bold-title-20-fontSize: 20px;
  --font-en-bold-title-20-textDecoration: none;
  --font-en-bold-title-20-fontFamily: Nunito;
  --font-en-bold-title-20-fontWeight: 700;
  --font-en-bold-title-20-fontStyle: normal;
  --font-en-bold-title-20-fontStretch: normal;
  --font-en-bold-title-20-fontStyleOld: Bold;
  --font-en-bold-title-20-letterSpacing: normal;
  --font-en-bold-title-20-lineHeight: 32px;
  --font-en-bold-title-20-paragraphIndent: 0;
  --font-en-bold-title-20-paragraphSpacing: 0;
  --font-en-bold-title-20-textCase: none;
  --font-en-bold-subtitle-18-fontSize: 18px;
  --font-en-bold-subtitle-18-textDecoration: none;
  --font-en-bold-subtitle-18-fontFamily: Nunito;
  --font-en-bold-subtitle-18-fontWeight: 700;
  --font-en-bold-subtitle-18-fontStyle: normal;
  --font-en-bold-subtitle-18-fontStretch: normal;
  --font-en-bold-subtitle-18-fontStyleOld: Bold;
  --font-en-bold-subtitle-18-letterSpacing: normal;
  --font-en-bold-subtitle-18-lineHeight: 32px;
  --font-en-bold-subtitle-18-paragraphIndent: 0;
  --font-en-bold-subtitle-18-paragraphSpacing: 0;
  --font-en-bold-subtitle-18-textCase: none;
  --font-en-bold-paragraph-16-fontSize: 16px;
  --font-en-bold-paragraph-16-textDecoration: none;
  --font-en-bold-paragraph-16-fontFamily: Nunito;
  --font-en-bold-paragraph-16-fontWeight: 700;
  --font-en-bold-paragraph-16-fontStyle: normal;
  --font-en-bold-paragraph-16-fontStretch: normal;
  --font-en-bold-paragraph-16-fontStyleOld: Bold;
  --font-en-bold-paragraph-16-letterSpacing: normal;
  --font-en-bold-paragraph-16-lineHeight: 24px;
  --font-en-bold-paragraph-16-paragraphIndent: 0;
  --font-en-bold-paragraph-16-paragraphSpacing: 0;
  --font-en-bold-paragraph-16-textCase: none;
  --font-en-bold-paragraph-14-fontSize: 14px;
  --font-en-bold-paragraph-14-textDecoration: none;
  --font-en-bold-paragraph-14-fontFamily: Nunito;
  --font-en-bold-paragraph-14-fontWeight: 700;
  --font-en-bold-paragraph-14-fontStyle: normal;
  --font-en-bold-paragraph-14-fontStretch: normal;
  --font-en-bold-paragraph-14-fontStyleOld: Bold;
  --font-en-bold-paragraph-14-letterSpacing: normal;
  --font-en-bold-paragraph-14-lineHeight: 24px;
  --font-en-bold-paragraph-14-paragraphIndent: 0;
  --font-en-bold-paragraph-14-paragraphSpacing: 0;
  --font-en-bold-paragraph-14-textCase: none;
  --font-en-bold-caption-13-fontSize: 13px;
  --font-en-bold-caption-13-textDecoration: none;
  --font-en-bold-caption-13-fontFamily: Nunito;
  --font-en-bold-caption-13-fontWeight: 800;
  --font-en-bold-caption-13-fontStyle: normal;
  --font-en-bold-caption-13-fontStretch: normal;
  --font-en-bold-caption-13-fontStyleOld: ExtraBold;
  --font-en-bold-caption-13-letterSpacing: normal;
  --font-en-bold-caption-13-lineHeight: 18px;
  --font-en-bold-caption-13-paragraphIndent: 0;
  --font-en-bold-caption-13-paragraphSpacing: 0;
  --font-en-bold-caption-13-textCase: none;
 --font-en-bold-caption-12-fontSize: 12px;
  --font-en-bold-caption-12-textDecoration: none;
  --font-en-bold-caption-12-fontFamily: Nunito;
  --font-en-bold-caption-12-fontWeight: 800;
  --font-en-bold-caption-12-fontStyle: normal;
  --font-en-bold-caption-12-fontStretch: normal;
  --font-en-bold-caption-12-fontStyleOld: ExtraBold;
  --font-en-bold-caption-12-letterSpacing: normal;
  --font-en-bold-caption-12-lineHeight: 16px;
  --font-en-bold-caption-12-paragraphIndent: 0;
  --font-en-bold-caption-12-paragraphSpacing: 0;
  --font-en-bold-caption-12-textCase: none;
  --font-en-bold-caption-11-fontSize: 11px;
  --font-en-bold-caption-11-textDecoration: none;
  --font-en-bold-caption-11-fontFamily: Nunito;
  --font-en-bold-caption-11-fontWeight: 800;
  --font-en-bold-caption-11-fontStyle: normal;
  --font-en-bold-caption-11-fontStretch: normal;
  --font-en-bold-caption-11-fontStyleOld: ExtraBold;
  --font-en-bold-caption-11-letterSpacing: normal;
  --font-en-bold-caption-11-lineHeight: 15px;
  --font-en-bold-caption-11-paragraphIndent: 0;
  --font-en-bold-caption-11-paragraphSpacing: 0;
  --font-en-bold-caption-11-textCase: none;
  --font-en-bold-caption-10-fontSize: 10px;
  --font-en-bold-caption-10-textDecoration: none;
  --font-en-bold-caption-10-fontFamily: Nunito;
  --font-en-bold-caption-10-fontWeight: 800;
  --font-en-bold-caption-10-fontStyle: normal;
  --font-en-bold-caption-10-fontStretch: normal;
  --font-en-bold-caption-10-fontStyleOld: ExtraBold;
  --font-en-bold-caption-10-letterSpacing: normal;
  --font-en-bold-caption-10-lineHeight: 16px;
  --font-en-bold-caption-10-paragraphIndent: 0;
  --font-en-bold-caption-10-paragraphSpacing: 0;
  --font-en-bold-caption-10-textCase: none;
  --font-en-medium-display-64-fontSize: 64px;
  --font-en-medium-display-64-textDecoration: none;
  --font-en-medium-display-64-fontFamily: Nunito;
  --font-en-medium-display-64-fontWeight: 500;
  --font-en-medium-display-64-fontStyle: normal;
  --font-en-medium-display-64-fontStretch: normal;
  --font-en-medium-display-64-fontStyleOld: Medium;
  --font-en-medium-display-64-letterSpacing: normal;
  --font-en-medium-display-64-lineHeight: 96px;
  --font-en-medium-display-64-paragraphIndent: 0;
  --font-en-medium-display-64-paragraphSpacing: 0;
  --font-en-medium-display-64-textCase: none;
  --font-en-medium-display-50-fontSize: 50px;
  --font-en-medium-display-50-textDecoration: none;
  --font-en-medium-display-50-fontFamily: Nunito;
  --font-en-medium-display-50-fontWeight: 500;
  --font-en-medium-display-50-fontStyle: normal;
  --font-en-medium-display-50-fontStretch: normal;
  --font-en-medium-display-50-fontStyleOld: Medium;
  --font-en-medium-display-50-letterSpacing: normal;
  --font-en-medium-display-50-lineHeight: 72px;
  --font-en-medium-display-50-paragraphIndent: 0;
  --font-en-medium-display-50-paragraphSpacing: 0;
  --font-en-medium-display-50-textCase: none;
 --font-en-medium-display-40-fontSize: 40px;
  --font-en-medium-display-40-textDecoration: none;
  --font-en-medium-display-40-fontFamily: Nunito;
  --font-en-medium-display-40-fontWeight: 500;
  --font-en-medium-display-40-fontStyle: normal;
  --font-en-medium-display-40-fontStretch: normal;
  --font-en-medium-display-40-fontStyleOld: Medium;
  --font-en-medium-display-40-letterSpacing: normal;
  --font-en-medium-display-40-lineHeight: 54px;
  --font-en-medium-display-40-paragraphIndent: 0;
  --font-en-medium-display-40-paragraphSpacing: 0;
  --font-en-medium-display-40-textCase: none;
  --font-en-medium-display-46-fontSize: 36px;
  --font-en-medium-display-46-textDecoration: none;
  --font-en-medium-display-46-fontFamily: Nunito;
  --font-en-medium-display-46-fontWeight: 500;
  --font-en-medium-display-46-fontStyle: normal;
  --font-en-medium-display-46-fontStretch: normal;
  --font-en-medium-display-46-fontStyleOld: Medium;
  --font-en-medium-display-46-letterSpacing: normal;
  --font-en-medium-display-46-lineHeight: 48px;
  --font-en-medium-display-46-paragraphIndent: 0;
  --font-en-medium-display-46-paragraphSpacing: 0;
  --font-en-medium-display-46-textCase: none;
  --font-en-medium-headline-24-fontSize: 24px;
  --font-en-medium-headline-24-textDecoration: none;
  --font-en-medium-headline-24-fontFamily: Nunito;
  --font-en-medium-headline-24-fontWeight: 500;
  --font-en-medium-headline-24-fontStyle: normal;
  --font-en-medium-headline-24-fontStretch: normal;
  --font-en-medium-headline-24-fontStyleOld: Medium;
  --font-en-medium-headline-24-letterSpacing: normal;
  --font-en-medium-headline-24-lineHeight: 32px;
  --font-en-medium-headline-24-paragraphIndent: 0;
  --font-en-medium-headline-24-paragraphSpacing: 0;
  --font-en-medium-headline-24-textCase: none;
  --font-en-medium-title-20-fontSize: 20px;
  --font-en-medium-title-20-textDecoration: none;
  --font-en-medium-title-20-fontFamily: Nunito;
  --font-en-medium-title-20-fontWeight: 500;
  --font-en-medium-title-20-fontStyle: normal;
  --font-en-medium-title-20-fontStretch: normal;
  --font-en-medium-title-20-fontStyleOld: Medium;
  --font-en-medium-title-20-letterSpacing: normal;
  --font-en-medium-title-20-lineHeight: 32px;
  --font-en-medium-title-20-paragraphIndent: 0;
  --font-en-medium-title-20-paragraphSpacing: 0;
  --font-en-medium-title-20-textCase: none;
  --font-en-medium-subtitle-18-fontSize: 17px;
  --font-en-medium-subtitle-18-textDecoration: none;
  --font-en-medium-subtitle-18-fontFamily: Nunito;
  --font-en-medium-subtitle-18-fontWeight: 500;
  --font-en-medium-subtitle-18-fontStyle: normal;
  --font-en-medium-subtitle-18-fontStretch: normal;
  --font-en-medium-subtitle-18-fontStyleOld: Medium;
  --font-en-medium-subtitle-18-letterSpacing: normal;
  --font-en-medium-subtitle-18-lineHeight: 32px;
  --font-en-medium-subtitle-18-paragraphIndent: 0;
  --font-en-medium-subtitle-18-paragraphSpacing: 0;
  --font-en-medium-subtitle-18-textCase: none;
  --font-en-medium-paragraph-16-fontSize: 16px;
  --font-en-medium-paragraph-16-textDecoration: none;
  --font-en-medium-paragraph-16-fontFamily: Nunito;
  --font-en-medium-paragraph-16-fontWeight: 500;
  --font-en-medium-paragraph-16-fontStyle: normal;
  --font-en-medium-paragraph-16-fontStretch: normal;
  --font-en-medium-paragraph-16-fontStyleOld: Medium;
  --font-en-medium-paragraph-16-letterSpacing: normal;
  --font-en-medium-paragraph-16-lineHeight: 24px;
  --font-en-medium-paragraph-16-paragraphIndent: 0;
  --font-en-medium-paragraph-16-paragraphSpacing: 0;
  --font-en-medium-paragraph-16-textCase: none;
  --font-en-medium-paragraph-14-fontSize: 14px;
  --font-en-medium-paragraph-14-textDecoration: none;
  --font-en-medium-paragraph-14-fontFamily: Nunito;
  --font-en-medium-paragraph-14-fontWeight: 500;
  --font-en-medium-paragraph-14-fontStyle: normal;
  --font-en-medium-paragraph-14-fontStretch: normal;
  --font-en-medium-paragraph-14-fontStyleOld: Medium;
  --font-en-medium-paragraph-14-letterSpacing: normal;
  --font-en-medium-paragraph-14-lineHeight: 24px;
  --font-en-medium-paragraph-14-paragraphIndent: 0;
  --font-en-medium-paragraph-14-paragraphSpacing: 0;
  --font-en-medium-paragraph-14-textCase: none;
  --font-en-medium-caption-13-fontSize: 13px;
  --font-en-medium-caption-13-textDecoration: none;
  --font-en-medium-caption-13-fontFamily: Nunito;
  --font-en-medium-caption-13-fontWeight: 500;
  --font-en-medium-caption-13-fontStyle: normal;
  --font-en-medium-caption-13-fontStretch: normal;
  --font-en-medium-caption-13-fontStyleOld: Medium;
  --font-en-medium-caption-13-letterSpacing: normal;
  --font-en-medium-caption-13-lineHeight: 18px;
  --font-en-medium-caption-13-paragraphIndent: 0;
  --font-en-medium-caption-13-paragraphSpacing: 0;
  --font-en-medium-caption-13-textCase: none;
 --font-en-medium-caption-12-fontSize: 12px;
  --font-en-medium-caption-12-textDecoration: none;
  --font-en-medium-caption-12-fontFamily: Nunito;
  --font-en-medium-caption-12-fontWeight: 500;
  --font-en-medium-caption-12-fontStyle: normal;
  --font-en-medium-caption-12-fontStretch: normal;
  --font-en-medium-caption-12-fontStyleOld: Medium;
  --font-en-medium-caption-12-letterSpacing: normal;
  --font-en-medium-caption-12-lineHeight: 16px;
  --font-en-medium-caption-12-paragraphIndent: 0;
  --font-en-medium-caption-12-paragraphSpacing: 0;
  --font-en-medium-caption-12-textCase: none;
  --font-en-medium-caption-11-fontSize: 11px;
  --font-en-medium-caption-11-textDecoration: none;
  --font-en-medium-caption-11-fontFamily: Nunito;
  --font-en-medium-caption-11-fontWeight: 500;
  --font-en-medium-caption-11-fontStyle: normal;
  --font-en-medium-caption-11-fontStretch: normal;
  --font-en-medium-caption-11-fontStyleOld: Medium;
  --font-en-medium-caption-11-letterSpacing: normal;
  --font-en-medium-caption-11-lineHeight: 15px;
  --font-en-medium-caption-11-paragraphIndent: 0;
  --font-en-medium-caption-11-paragraphSpacing: 0;
  --font-en-medium-caption-11-textCase: none;
  --font-en-medium-caption-10-fontSize: 10px;
  --font-en-medium-caption-10-textDecoration: none;
  --font-en-medium-caption-10-fontFamily: Nunito;
  --font-en-medium-caption-10-fontWeight: 500;
  --font-en-medium-caption-10-fontStyle: normal;
  --font-en-medium-caption-10-fontStretch: normal;
  --font-en-medium-caption-10-fontStyleOld: Medium;
  --font-en-medium-caption-10-letterSpacing: normal;
  --font-en-medium-caption-10-lineHeight: 16px;
  --font-en-medium-caption-10-paragraphIndent: 0;
  --font-en-medium-caption-10-paragraphSpacing: 0;
  --font-en-medium-caption-10-textCase: none;
  --font-en-light-display-64-fontSize: 64px;
  --font-en-light-display-64-textDecoration: none;
  --font-en-light-display-64-fontFamily: Nunito;
  --font-en-light-display-64-fontWeight: 300;
  --font-en-light-display-64-fontStyle: normal;
  --font-en-light-display-64-fontStretch: normal;
  --font-en-light-display-64-fontStyleOld: Light;
  --font-en-light-display-64-letterSpacing: normal;
  --font-en-light-display-64-lineHeight: 96px;
  --font-en-light-display-64-paragraphIndent: 0;
  --font-en-light-display-64-paragraphSpacing: 0;
  --font-en-light-display-64-textCase: none;
  --font-en-light-display-50-fontSize: 50px;
  --font-en-light-display-50-textDecoration: none;
  --font-en-light-display-50-fontFamily: Nunito;
  --font-en-light-display-50-fontWeight: 300;
  --font-en-light-display-50-fontStyle: normal;
  --font-en-light-display-50-fontStretch: normal;
  --font-en-light-display-50-fontStyleOld: Light;
  --font-en-light-display-50-letterSpacing: normal;
  --font-en-light-display-50-lineHeight: 72px;
  --font-en-light-display-50-paragraphIndent: 0;
  --font-en-light-display-50-paragraphSpacing: 0;
  --font-en-light-display-50-textCase: none;
  --font-en-light-display-40-fontSize: 40px;
  --font-en-light-display-40-textDecoration: none;
  --font-en-light-display-40-fontFamily: Nunito;
  --font-en-light-display-40-fontWeight: 300;
  --font-en-light-display-40-fontStyle: normal;
  --font-en-light-display-40-fontStretch: normal;
  --font-en-light-display-40-fontStyleOld: Light;
  --font-en-light-display-40-letterSpacing: normal;
  --font-en-light-display-40-lineHeight: 54px;
  --font-en-light-display-40-paragraphIndent: 0;
  --font-en-light-display-40-paragraphSpacing: 0;
  --font-en-light-display-40-textCase: none;
  --font-en-light-display-46-fontSize: 36px;
  --font-en-light-display-46-textDecoration: none;
  --font-en-light-display-46-fontFamily: Nunito;
  --font-en-light-display-46-fontWeight: 300;
  --font-en-light-display-46-fontStyle: normal;
  --font-en-light-display-46-fontStretch: normal;
  --font-en-light-display-46-fontStyleOld: Light;
  --font-en-light-display-46-letterSpacing: normal;
  --font-en-light-display-46-lineHeight: 48px;
  --font-en-light-display-46-paragraphIndent: 0;
  --font-en-light-display-46-paragraphSpacing: 0;
  --font-en-light-display-46-textCase: none;
  --font-en-light-headline-24-fontSize: 24px;
  --font-en-light-headline-24-textDecoration: none;
  --font-en-light-headline-24-fontFamily: Nunito;
  --font-en-light-headline-24-fontWeight: 300;
  --font-en-light-headline-24-fontStyle: normal;
  --font-en-light-headline-24-fontStretch: normal;
  --font-en-light-headline-24-fontStyleOld: Light;
  --font-en-light-headline-24-letterSpacing: normal;
  --font-en-light-headline-24-lineHeight: 32px;
  --font-en-light-headline-24-paragraphIndent: 0;
  --font-en-light-headline-24-paragraphSpacing: 0;
  --font-en-light-headline-24-textCase: none;
  --font-en-light-title-20-fontSize: 20px;
  --font-en-light-title-20-textDecoration: none;
  --font-en-light-title-20-fontFamily: Nunito;
  --font-en-light-title-20-fontWeight: 300;
  --font-en-light-title-20-fontStyle: normal;
  --font-en-light-title-20-fontStretch: normal;
  --font-en-light-title-20-fontStyleOld: Light;
  --font-en-light-title-20-letterSpacing: normal;
  --font-en-light-title-20-lineHeight: 32px;
  --font-en-light-title-20-paragraphIndent: 0;
  --font-en-light-title-20-paragraphSpacing: 0;
  --font-en-light-title-20-textCase: none;
  --font-en-light-subtitle-18-fontSize: 18px;
  --font-en-light-subtitle-18-textDecoration: none;
  --font-en-light-subtitle-18-fontFamily: Nunito;
  --font-en-light-subtitle-18-fontWeight: 300;
  --font-en-light-subtitle-18-fontStyle: normal;
  --font-en-light-subtitle-18-fontStretch: normal;
  --font-en-light-subtitle-18-fontStyleOld: Light;
  --font-en-light-subtitle-18-letterSpacing: normal;
  --font-en-light-subtitle-18-lineHeight: 32px;
  --font-en-light-subtitle-18-paragraphIndent: 0;
  --font-en-light-subtitle-18-paragraphSpacing: 0;
  --font-en-light-subtitle-18-textCase: none;
  --font-en-light-paragraph-16-fontSize: 16px;
  --font-en-light-paragraph-16-textDecoration: none;
  --font-en-light-paragraph-16-fontFamily: Nunito;
  --font-en-light-paragraph-16-fontWeight: 300;
  --font-en-light-paragraph-16-fontStyle: normal;
  --font-en-light-paragraph-16-fontStretch: normal;
  --font-en-light-paragraph-16-fontStyleOld: Light;
  --font-en-light-paragraph-16-letterSpacing: normal;
  --font-en-light-paragraph-16-lineHeight: 24px;
  --font-en-light-paragraph-16-paragraphIndent: 0;
  --font-en-light-paragraph-16-paragraphSpacing: 0;
  --font-en-light-paragraph-16-textCase: none;
  --font-en-light-paragraph-14-fontSize: 14px;
  --font-en-light-paragraph-14-textDecoration: none;
  --font-en-light-paragraph-14-fontFamily: Nunito;
  --font-en-light-paragraph-14-fontWeight: 300;
  --font-en-light-paragraph-14-fontStyle: normal;
  --font-en-light-paragraph-14-fontStretch: normal;
  --font-en-light-paragraph-14-fontStyleOld: Light;
  --font-en-light-paragraph-14-letterSpacing: normal;
  --font-en-light-paragraph-14-lineHeight: 24px;
  --font-en-light-paragraph-14-paragraphIndent: 0;
  --font-en-light-paragraph-14-paragraphSpacing: 0;
  --font-en-light-paragraph-14-textCase: none;
  --font-en-light-caption-13-fontSize: 13px;
  --font-en-light-caption-13-textDecoration: none;
  --font-en-light-caption-13-fontFamily: Nunito;
  --font-en-light-caption-13-fontWeight: 300;
  --font-en-light-caption-13-fontStyle: normal;
  --font-en-light-caption-13-fontStretch: normal;
  --font-en-light-caption-13-fontStyleOld: Light;
  --font-en-light-caption-13-letterSpacing: normal;
  --font-en-light-caption-13-lineHeight: 18px;
  --font-en-light-caption-13-paragraphIndent: 0;
  --font-en-light-caption-13-paragraphSpacing: 0;
  --font-en-light-caption-13-textCase: none;
 --font-en-light-caption-12-fontSize: 12px;
  --font-en-light-caption-12-textDecoration: none;
  --font-en-light-caption-12-fontFamily: Nunito;
  --font-en-light-caption-12-fontWeight: 300;
  --font-en-light-caption-12-fontStyle: normal;
  --font-en-light-caption-12-fontStretch: normal;
  --font-en-light-caption-12-fontStyleOld: Light;
  --font-en-light-caption-12-letterSpacing: normal;
  --font-en-light-caption-12-lineHeight: 16px;
  --font-en-light-caption-12-paragraphIndent: 0;
  --font-en-light-caption-12-paragraphSpacing: 0;
  --font-en-light-caption-12-textCase: none;
  --font-en-light-caption-11-fontSize: 11px;
  --font-en-light-caption-11-textDecoration: none;
  --font-en-light-caption-11-fontFamily: Nunito;
  --font-en-light-caption-11-fontWeight: 300;
  --font-en-light-caption-11-fontStyle: normal;
  --font-en-light-caption-11-fontStretch: normal;
  --font-en-light-caption-11-fontStyleOld: Light;
  --font-en-light-caption-11-letterSpacing: normal;
  --font-en-light-caption-11-lineHeight: 16px;
  --font-en-light-caption-11-paragraphIndent: 0;
  --font-en-light-caption-11-paragraphSpacing: 0;
  --font-en-light-caption-11-textCase: none;
  --font-en-light-caption-10-fontSize: 10px;
  --font-en-light-caption-10-textDecoration: none;
  --font-en-light-caption-10-fontFamily: Nunito;
  --font-en-light-caption-10-fontWeight: 300;
  --font-en-light-caption-10-fontStyle: normal;
  --font-en-light-caption-10-fontStretch: normal;
  --font-en-light-caption-10-fontStyleOld: Light;
  --font-en-light-caption-10-letterSpacing: normal;
  --font-en-light-caption-10-lineHeight: 16px;
  --font-en-light-caption-10-paragraphIndent: 0;
  --font-en-light-caption-10-paragraphSpacing: 0;
  --font-en-light-caption-10-textCase: none;
  --effect-elevation-1-type: dropShadow;
  --effect-elevation-1-radius: 4;
  --effect-elevation-1-color: rgba(5, 51, 133, 0.05);
  --effect-elevation-1-offset-x: 0;
  --effect-elevation-1-offset-y: 2;
  --effect-elevation-1-spread: 0;
  --effect-elevation-2-type: dropShadow;
  --effect-elevation-2-radius: 14;
  --effect-elevation-2-color: rgba(8, 8, 52, 0.05);
  --effect-elevation-2-offset-x: 0;
  --effect-elevation-2-offset-y: 2;
  --effect-elevation-2-spread: 0;
  --effect-elevation-3-type: dropShadow;
  --effect-elevation-3-radius: 32;
  --effect-elevation-3-color: rgba(8, 8, 52, 0.07);
  --effect-elevation-3-offset-x: 0;
  --effect-elevation-3-offset-y: 3;
  --effect-elevation-3-spread: -10;
  --effect-elevation-4-0-type: dropShadow;
  --effect-elevation-4-0-radius: 21;
  --effect-elevation-4-0-color: rgba(8, 8, 52, 0.2);
  --effect-elevation-4-0-offset-x: 0;
  --effect-elevation-4-0-offset-y: 2;
  --effect-elevation-4-0-spread: -5;
  --effect-elevation-4-1-type: dropShadow;
  --effect-elevation-4-1-radius: 6;
  --effect-elevation-4-1-color: rgba(8, 8, 52, 0.08);
  --effect-elevation-4-1-offset-x: 0;
  --effect-elevation-4-1-offset-y: 1;
  --effect-elevation-4-1-spread: 0;

  --brand-font-family: Nunito, serif;
}
