.lab-header {
  padding: 1.25rem 1.875rem;

  .page-title {
    margin-right: 1.25rem;
    padding: 0 0.25rem;
  }

  .page-actions {
    padding: 0.5rem 2rem;
    border-right: 1px solid #DFE0EB;
    width: 3.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
