.lab-side-nav {
  background-color: var(--brand-dark-blue);
  padding: 2rem 1rem;
}
.heading {
  display: flex;
  padding: 0 0.5rem 3rem;
  span {
    margin-left: 1rem;
  }
}
.lab-navs {
  margin: 0 -1rem;
  padding: 0.75rem 0;
  a {
    display: flex;
    text-decoration: none;
    padding: 0.875rem 1.875rem;
    border-left: 3px solid var(--brand-dark-blue);
    span {
      margin-left: 1.5rem;
    }
    &.is-active {
      background-color: #4B5BBC;
      border-left: 3px solid white;
    }
  }
  &.secondary-navs {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
