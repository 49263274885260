.modalContainer {
  background-color: var(--brand-white);
  border: 1px solid var(--brand-divider-white);
  border-radius: 8px;
  z-index: 1000;
  height: 100%;
}

.modalGradientBorderContainer {
  padding: 2px;
  border-radius: 8px;
  border: none;
  background: var(--brand-primary-border);
  height: fit-content;
  z-index: 1000;
}

.modalGradientBorder {
  border: none;
  padding: 0;
  height: 100%;
}

.normalModalContainer {
  padding: 16px;
}

.centeredModalContainer {
  padding: 32px;
  padding-top: 48px;
}